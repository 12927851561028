import "./Home.css";
import Banner from "../../Components/Banner/Banner";
import Featured from "../../Components/Featured/Featured";
import MostFavorite from "../../Components/MostFavorite/MostFavorite";
import InstaShop from "../../Components/InstaShop";
import SkinTips from "../../Components/SkinTips";
// import HomeAbout from "../../Components/HomeAbout";
import { Helmet } from "react-helmet-async";
import icon from "../../Images/ogIcon.png";
import HomePersonalCare from "../../Components/HomePersonalCare/HomePersonalCare";
import ShopByConcern from "../ShopByConcern/ShopByConcern";

const Home = () => {


  return (
    <div className="home">
      <div>
        <Helmet>
          <title>Clariss Bangladesh</title>
          <meta
            name="description"
            content="Welcome to Clariss Bangladesh – where nature meets beauty. Discover our curated collection of organic and natural products designed for your well-being. From skincare to home essentials, shop consciously and live beautifully. Embrace the beauty of nature with Clariss Bangladesh."
          />
          <link rel="canonical" href="https://clariss.com.bd" />
          <meta property="og:title" content="Clariss Bangladesh" />
          <meta
            property="og:description"
            content="Welcome to Clariss Bangladesh – where nature meets beauty. Discover our curated collection of organic and natural products designed for your well-being. From skincare to home essentials, shop consciously and live beautifully. Embrace the beauty of nature with Clariss Bangladesh."
          />
          <meta property="og:image" content={icon} />
          <meta property="og:url" content="https://clariss.com.bd" />
          <meta property="og:type" content="website" />
        </Helmet>

        <Banner></Banner>
        <HomePersonalCare></HomePersonalCare>
        <ShopByConcern></ShopByConcern>
        <MostFavorite></MostFavorite>
        <Featured></Featured>
        <SkinTips></SkinTips>
        <InstaShop></InstaShop>
      </div>
    </div>
  );
};

export default Home;
