import React, { useContext, useRef, useState } from "react";
import "./Product.css";
import { ThemeContext } from "../../Contexts/ThemeContext";
import { toast } from "react-hot-toast";
import { addToDb } from "../../utilities/CartDb";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../Modal/Modal";
import StarRating from "../Ratings";

const Product = ({ product, list }) => {
  const { cart, setCart } = useContext(ThemeContext);
  const navigate = useNavigate();
  const dialogueRef = useRef();
  const [mouseHover, setMouseHover] = useState(false);

  const handleAddToCart = (item) => {
    let newCart = [];
    const exists = cart.find((product) => product._id == item._id);
    if (!exists) {
      item.quantity = 1;
      newCart = [...cart, item];
    } else {
      item.quantity = exists.quantity + 1;
      const rest = cart.filter((product) => product._id !== item._id);
      newCart = [...rest, item];
    }
    setCart(newCart);
    addToDb(item._id);

    toast.success("ADDED TO CART");
  };

  const handleModal = () => {
    dialogueRef.current.showModal();
    document.body.style.overflow = "hidden";
  };

  const handleBlurClose = () => {
    dialogueRef.current.close();
    document.body.style.overflow = "visible";
  };
  return (
    <div className={`product ${list ? "py-6" : ""}`}>
      <div
        onMouseEnter={() => setMouseHover(true)}
        onMouseLeave={() => setMouseHover(false)}
        className="img-container cursor-pointer"
      >
        <div
          onClick={() => navigate(`/product/${product?.slug}`)}
          className="img-div rounded relative"
        >
          {JSON.stringify(product?.on_sale) === "true" && (
            <div className="bg-accent absolute w-[40px] h-[40px] left-2 top-2 z-50 flex justify-center items-center rounded-full sm:w-[50px] sm:h-[50px]">
              <p style={{ color: "#fff", fontFamily: "Roboto-Regular" }}>
                -
                {Math.floor(
                  ((product.regular_price - product.sale_price) * 100) /
                    product.regular_price
                )}
                %
              </p>
            </div>
          )}
          {product?.stock_quantity < 1 && (
            <div className="bg-[#cccccc] absolute w-[50px] h-[50px] left-2 top-2 text-center z-50 flex justify-center items-center rounded-full sm:w-[50px] sm:h-[50px]">
              <p style={{ color: "#fff", fontFamily: "Roboto-Regular" }}>
                Stock Out
              </p>
            </div>
          )}
          <img
            width={250}
            height={250}
            src={
              mouseHover
                ? product?.images[1]?.src
                  ? product?.images[1]?.src
                  : product?.images[0]?.src
                : product?.images[0]?.src
            }
            alt=""
            className="rounded"
          />
        </div>
        {/* The button to open modal */}
        {/* <button className="details-btn" onClick={handleModal}>
          QUICK VIEW
        </button> */}
        <Modal
          handleModal={handleModal}
          handleBlurClose={handleBlurClose}
          product={product}
          dialogueRef={dialogueRef}
        ></Modal>
        {/* <button onClick={() => handleAddToCart(product)} className="add-btn">
          ADD TO CART
        </button> */}
      </div>

      <div className="product-details">
        {/* ...........variations............... */}
        {product?.variations?.length > 1 && (
          <div
            className={`flex gap-4 mb-3 h-[40px] bg-primary items-center w-[170px] md:w-[235px] lg:w-[170px] xl:w-[200px] 2xl:w-[265px] ${
              list ? "" : "mx-auto"
            }`}
          >
            {product?.variations.slice(0, 3)?.map((variation) => {
              let code = variation?.code;
              let style = { backgroundColor: code };
              return (
                <Link
                  to={`/product/${variation.product_id}`}
                  className={`w-[30px] h-[30px] cursor-pointer rounded-full`}
                  style={style}
                >
                  {/* <p className="opacity-0 hover:opacity-60 bg-secondary text-primary px-2 absolute ml-[-50px]">
                    {variation.color}
                  </p> */}
                </Link>
              );
            })}{" "}
            +
            <Link to={`/product/${product._id}`}>
              {product.variations.length - 3}
            </Link>
          </div>
        )}
        <p
          onClick={() => navigate(`/product/${product.slug}`)}
          className="limit-lines text-center cursor-pointer font-bold"
          title={product?.name}
        >
          {product?.name}
        </p>
        {window.innerWidth > 640 && (
          <div
            className={`text-[#f3c621] flex ${
              list ? "" : "sm:justify-center"
            } `}
          >
            {/* <StarRating rating={product.average_rating} size={20}></StarRating> */}
          </div>
        )}
        <p>
          {list ? (
            <div
              className="limit-lines"
              dangerouslySetInnerHTML={{ __html: product?.short_description }}
            />
          ) : (
            ""
          )}
        </p>
        <div className={`flex gap-2 ${list ? "" : "sm:justify-center"} `}>
          <p className="text-accent font-bold sm:text-lg">
            {" "}
            {JSON.stringify(product?.on_sale) === "true"
              ? product?.sale_price
              : product?.regular_price}{" "}
            BDT.
          </p>
          {JSON.stringify(product.on_sale) === "true" && (
            <p
              style={{
                textDecorationLine: "line-through",
                textDecorationStyle: "solid",
                color: "#000",
                opacity: 0.5,
              }}
            >
              {`${product?.regular_price} BDT.`}
            </p>
          )}
        </div>
        {/* <p className="text-accent font-bold">
          App price :{" "}
          {product?.on_sale == "true"
            ? Math.floor(product?.sale_price - product?.sale_price * 0.05)
            : Math.floor(
                product?.regular_price - product?.regular_price * 0.05
              )}{" "}
          TK.
        </p> */}
        {/* <div className="bg-accent flex justify-center items-center mt-4 w-[60px] h-[20px] mx-auto sm:pt-1 rounded-full">
          <p className="text-primary text-xs">{product?.attributes[0]?.options}</p>
        </div> */}
        {product?.stock_quantity > 0 ? (
          <button
            onClick={() => handleAddToCart(product)}
            className={`add-btn bg-accent text-white`}
          >
            ADD TO CART
          </button>
        ) : (
          <button
            className={`add-btn bg-[#cccccc] text-[#ffffff] pointer-events-none`}
          >
            ADD TO CART
          </button>
        )}
      </div>
    </div>
  );
};

export default Product;
